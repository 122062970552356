<template>
  <div class="container">
    <div class="containerBox">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide item">
            <img
              class="img"
              src="../../public/images/home/banner1.png"
              alt=""
              style="width: 100%"
            />
          </div>
          <div class="swiper-slide item">
            <img
              class="img"
              src="../../public/images/home/banner2.png"
              alt=""
              style="width: 100%"
            />
          </div>
        </div>
        <!-- style="background-image:none" -->
        <!-- <div class="swiper-button-prev" style="background-image:none">
            <i class="el-icon-arrow-left" style="font-size: 0.5rem;"></i>
        </div>
        <div class="swiper-button-next" style="background-image:none">
            <i class="el-icon-arrow-right" style="font-size: 0.5rem;"></i>
        </div> -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  mounted() {
    this.bannerPoint();
  },
  methods: {
    bannerPoint() {
      new Swiper(".swiper-container", {
        loop: true,
        autoplay: {
          delay: 4000, //1秒切换一次
          disableOnInteraction: false,
        },
        updateOnImagesReady: true,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量
        //spaceBetween : '10%',按container的百分比

        observer: true, //在 Swiper 的上启用动态检查器(Mutation Observer)，如果你更改swiper 的样式（隐藏/显示）或修改其子元素（添加/删除幻灯片），Swiper 会更新（重新初始化）并触发 observerUpdate 事件。
        observeParents: true,
        observeSlideChildren: true,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationClickable: true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next", // 右按钮
          prevEl: ".swiper-button-prev", // 左按钮
        },
      });
    },
  },
};
</script>

<style>
.el-icon-arrow-right:before,.el-icon-arrow-left:before{
    opacity: .6;
    background-color: #f3f3f3;
    border-radius: 50%;
}
</style>