import Vue from "vue";
import Router from "vue-router"
import home from "@/views/index.vue"
import products from "@/views/products.vue"
import partner from "@/views/partner.vue"
import advantages from "@/views/proAdvantages.vue"
import recruitArti from "@/views/recruitArti.vue"
import aboutUs from "@/views/aboutUs.vue"
Vue.use(Router)
const myPush = Router.prototype.push
let router = new Router({
    routes:[{
        name:"home",
        path:"/home",
        component:home
    },{
        path:'/',
        component:home
    },{
        //服务项目
        name:"products",
        path:"/products",
        component:products
    },{
        // 招募合伙人
        name:"partner",
        path:"/partner",
        component:partner
    },{
        // 产品优势
        name:"advantages",
        path:"/advantages",
        component:advantages
    },{
        // 招募技师
        name:"recruitArti",
        path:"/recruitArti",
        component:recruitArti
    },{
        //关于我们
        name:"aboutUs",
        path:"/aboutUs",
        component:aboutUs
    }],
    scrollBehavior(to, from, savedPosition) {
        return { y: 0 }
    }
})
Router.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        myPush.call(this, location)
    } else {
        myPush.call(this, location, () => { }, () => { })
    }
}
export default router