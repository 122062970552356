import Vue from "vue"
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state:{
        size:0
    },
    mutations:{
        setSize(state,value){
            state.size = value
        }
    }
})