<template>
  <div>
    <div class="header_img">
      <img
        src="../../public/images/advantages/advImg.png"
        style="width: 100%"
      />
    </div>
    <div class="page_main_box">
      <div class="page_content_box">
        <div class="item_tit" style="margin-top: 80px;">点约特色功能</div>
        <div class="item_subtitle">
          <div class="content">真实认证技师 按摩SPA上门服务平台</div>
          <div class="line"></div>
        </div>
        <!-- 功能特色列表 -->
        <div class="advantages_list">
          <div class="advantages_item">
            <img src="../../public/images/advantages/adv1.png" alt="">
          </div>
          <div class="advantages_item">
            <img src="../../public/images/advantages/adv2.png" alt="">
          </div>
          <div class="advantages_item">
            <img src="../../public/images/advantages/adv3.png" alt="">
          </div>
        </div>
        <div class="item_tit" style="margin-top: 80px;">点约服务优势</div>
        <div class="item_subtitle">
          <div class="content">真实认证技师 按摩SPA上门服务平台</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <!-- 服务优势列表 -->
    <div class="sever_list">
      <div class="sever_item">
        <img src="../../public/images/advantages/adv4.png" alt="">
      </div>
      <div class="sever_item">
        <img src="../../public/images/advantages/adv5.png" alt="">
      </div>
      <div class="sever_item">
        <img src="../../public/images/advantages/adv6.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "advantages",
  data() {
    return {};
  },
};
</script>

<style>
.page_main_box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page_content_box {
  width: 86%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item_tit {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 5px;
  color: #353638;
}
.item_subtitle {
  font-size: 10px;
  letter-spacing: 3px;
  margin: 8px 0;
  position: relative;
  color: #353638;
}
.item_subtitle > .line {
  width: 100%;
  height: 4px;
  background-color: #ffbf94;
  margin-top: -5px;
}
.advantages_list{
  margin-top:30px ;
  display: flex;
  justify-content: space-between;
}
.advantages_item{
  width: 30%;
}
.advantages_item>img{
  width: 90%;
}
.sever_list{
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
  margin-bottom: 40px;
}
.sever_item{
  width: 28%;
}
.sever_item>img{
  width: 100%;
}
</style>