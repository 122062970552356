<template>
  <div>
    <div class="header_img">
      <img src="../../public/images/products/Banner.png" style="width: 100%;" alt="">
    </div>
    <div class="page_main_box">
      <div class="page_content_box">
        <!-- 项目列表 -->
        <div class="products_list">
          <div class="products_item">
            <img src="../../public/images/products/pro1.png" alt="">
          </div>
          <div class="products_item">
            <img src="../../public/images/products/pro2.png" alt="">
          </div>
          <div class="products_item">
            <img src="../../public/images/products/pro3.png" alt="">
          </div>
          <div class="products_item">
            <img src="../../public/images/products/pro4.png" alt="">
          </div>
          <div class="products_item">
            <img src="../../public/images/products/pro5.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"products",
    data(){
        return{
            pageH:0
        }
    },
    mounted(){
      //获取视口的高度
      this.pageH = document.body.clientHeight
    }
}
</script>

<style scoped>
.header_img{
  width: 100%;
}
.page_main_box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page_content_box {
  width: 86%;
}
.products_list{
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
}
.products_item{
  width: 33.33%;
}
.products_item>img{
  width: 90%;
  margin-top: 20px;
}
</style>