<template>
  <div>
    <div class="header_img">
      <img
        src="../../public/images/partner/banner.png"
        style="width: 100%"
        alt=""
      />
    </div>
    <div class="join_conditions">
      <img
        src="../../public/images/partner/condition.png"
        style="width: 40%"
        alt=""
      />
    </div>
    <div class="join_info_box">
      <div class="join_info_list">
        <div class="info_item">
          <!-- <div class="info_tit"><span>*</span>您需要加盟的城市</div>
          <el-select v-model="value" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="info_item">
          <!-- <div class="info_tit"><span>*</span>您是否有过团队管理经验</div>
          <el-select v-model="value" placeholder="请选择" size="mini">
            <el-option
              v-for="item in [
                { value: 1, label: '是' },
                { value: 2, label: '否' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
      
        <!--  -->
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "partner",
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style scoped>
.join_conditions {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}
.join_info_box {
  display: flex;
  justify-content: center;
}
.join_info_list {
  width: 60%;
}
.info_item {
  font-size: 14px;
  color: #353638;
}
.info_tit {
  /* margin: 10px 0; */
}
.info_tit > span {
  color: red;
}
.el-input--mini{
  width: 400px;
}
</style>