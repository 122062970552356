<template>
  <div style="background-image:linear-gradient(to right,rgb(251, 122, 71),rgb(238, 126, 106))">
    <div class="header_img">
      <img
        src="../../public/images/recruitArti/banner.png"
        style="width: 100%"
      />
      <!-- <img src="../../public/images/recruitArti/step.png" style="width: 60%;margin: 100px 0;"/> -->
      <div class="recruit_box" style="margin-top: -0.2rem;">
        <span class="recruit_tit">请填写您的基本信息</span>
        <div class="recruit_content">
          <div class="recruit_left">
            <div class="info_tit">
              您的姓名<span> *</span
              ><span v-if="false" style="font-size: 0.098rem">未填写</span>
            </div>
            <el-input
              placeholder="请输入您的姓名"
              size="mini"
              style="width: 3.2rem"
              v-model="data.name"
              clearable
            />
            <div class="info_tit">
              您的工作城市 (例如 : 深圳 )<span> *</span>
            </div>
            <el-input
              placeholder="请输入您的工作城市"
              size="mini"
              style="width: 3.2rem"
              v-model="data.city"
              clearable
            />
            <div class="info_tit">性别<span> *</span></div>
            <el-select
              v-model="data.sex"
              placeholder="请选择性别"
              size="mini"
              style="width: 3.2rem"
            >
              <el-option
                v-for="item in [
                  { value: 1, label: '男' },
                  { value: 2, label: '女' },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
            <div class="info_tit">年龄<span> *</span></div>
            <el-input
              placeholder="请输入您的年龄"
              size="mini"
              v-model="data.age"
              style="width: 3.2rem"
              clearable
            />
            <div class="info_tit">接单区域 ( 例如:深圳 )<span> *</span></div>
            <el-input
              placeholder="请输入您的接单区域"
              size="mini"
              style="width: 3.2rem"
              v-model="data.region"
              clearable
            />
          </div>
          <div class="recruit_right">
            <div class="info_tit">工作经验<span> *</span></div>
            <el-select
              v-model="data.working_years"
              placeholder="请选择工作经验"
              size="mini"
              style="width: 3.2rem"
            >
              <el-option
                v-for="item in [
                  { value: 3, label: '一年以下' },
                  { value: 4, label: '1~3年' },
                  { value: 5, label: '3~7年' },
                  { value: 6, label: '7年以上' },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
            <div class="info_tit">工作经历</div>
            <el-input
              type="textarea"
              placeholder="请输入工作经历"
              v-model="data.working_experience"
              maxlength="100"
              show-word-limit
            >
            </el-input>
            <div class="info_tit">请输入电话号码<span> *</span></div>
            <el-input
              placeholder="请输入您的电话号码"
              size="mini"
              style="width: 3.2rem"
              v-model="data.phone"
              clearable
            />
            <div
              style="display: flex; margin-top: 0.12rem; align-items: center"
            >
              <el-input
                placeholder="请输入验证码"
                size="mini"
                style="width: 1.6rem"
                v-model="data.verify_code"
                clearable
              />
              <div class="btn" :style="{width:isMobile?'2.5rem':''}" @click="getCode">{{ text }}</div>
            </div>
            <div class="btn commit" @click="commit" style="margin-left: 1.3rem">
              提交信息
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqRecruitArti, reqCode } from "@/api/requests";
export default {
  name: "recruitArti",
  data() {
    return {
      data: {
        //技师招聘信息
        source_type: 3, //1用户端 2.技师端 3官网
        city: "",
        name: "",
        sex: "",
        use_smart_phone: "", //是否会使用智能机
        age: "",
        region: "", //服务区域
        working_years: "", //工作经验年限
        working_experience: "", //工作经历输入
        picture1: "",
        picture2: "",
        picture3: "",
        phone: "",
        verify_code: "",
      },
      text: "获取验证码",
      status: true,
      num: 60,
      timer: null,
      rules: {
        phone: {
          rules: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
          msg: "请输入11位手机号码",
        },
        verify_code: {
          rules: /^\d{4}$/,
          msg: "请输入正确的验证码",
        },
      },
      isMobile:false,
    };
  },
  computed: {
    size() {
      return this.$store.state.size;
    },
  },
  watch: {
    size() {
      if (this.size < 560) {
        this.isMobile = true;
      }
    }
  },
  mounted() {
    //   this.recruitArti()
    // this.$message({
    //   type: "success",
    //   message: "请输入查询号码",
    // });
  },
  methods: {
    //获取验证码
    async getCode() {
      if (!this.data.phone) {
        this.$message({
          type: "error",
          message: "请输入手机号",
        });
      } else if (!this.validate("phone")) {
      } else {
        this.status = false;
        this.timer = setInterval(() => {
          if (this.num === 0) {
            this.timer && this.clearTimer();
            this.reset();
          } else {
            this.loading();
          }
        }, 1000);
        const res = await reqCode({ phone: this.data.phone, code: "+86" });
        console.log("获取验证码", res);
        if (res.message == "success") {
          this.$message({
            type: "success",
            message: "获取验证码成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "获取验证码失败",
          });
        }
      }
    },
    async recruitArti() {
      const res = await reqRecruitArti();
      console.log("申请", res);
    },
    reset() {
      this.num = 60;
      this.text = "获取验证码";
      this.status = true;
    },
    loading() {
      this.num -= 1;
      this.text = "重新获取(" + this.num + ")";
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.status = false;
    },
    //提交信息
    async commit() {
      if (!this.data.name) {
        this.$message({
          type: "error",
          message: "请输入姓名",
        });
      } else if (!this.data.city) {
        this.$message({
          type: "error",
          message: "请输入工作城市",
        });
      } else if (!this.data.sex) {
        this.$message({
          type: "error",
          message: "请选择性别",
        });
      } else if (!this.data.age) {
        this.$message({
          type: "error",
          message: "请输入年龄",
        });
      } else if (!this.data.region) {
        this.$message({
          type: "error",
          message: "请输入接单区域",
        });
      } else if (!this.data.working_years) {
        this.$message({
          type: "error",
          message: "请选择工作年限",
        });
      } else if (!this.data.phone) {
        this.$message({
          type: "error",
          message: "请输入电话号码",
        });
      } else if (!this.validate("phone")) {
      } else if (!this.data.verify_code) {
        this.$message({
          type: "error",
          message: "请输入验证码",
        });
      } else if (!this.validate("verify_code")) {
      } else {
        console.log("参数", this.data, this.fileList);
        const res = await reqRecruitArti(this.data);
        console.log("招募申请", res);
        if (res.result) {
          this.$message({
            type: "error",
            message: "提交申请失败",
          });
        } else {
          this.$message({
            type: "success",
            message: "提交申请成功",
          });
          this.data.name = ""
          this.data.city = ""
          this.data.sex = ""
          this.data.age = ""
          this.data.region = ""
          this.data.working_years = ""
          this.data.working_experience = ""
          this.data.phone = ""
          this.data.verify_code = ""
        }
      }
    },
    // 判断验证是否符合要求
    validate(key) {
      let bool = true;
      if (!this.rules[key].rules.test(this.data[key])) {
        this.$message({
          type: "error",
          message: this.rules[key].msg,
        });
        bool = false;
        return false;
      }
      return bool;
    },
  },
};
</script>

<style>
.el-input--mini {
  width: 3.2rem;
}
.el-upload-list__item-name {
  font-size: 0.12rem;
}
.el-upload-list--picture {
  max-height: 0.8rem;
}
.el-icon-error,
.el-icon-success {
  font-size: 0.16rem;
}
.el-textarea .el-input__count{
  font-size: 0.1rem;
}
.el-textarea .el-input__count{
  font-size: 0.16rem;
}
.el-textarea .el-input__count{
  height: 0.3rem;
  line-height: 0.3rem;
}
.el-message__content {
  font-size: 0.1rem;
}
.el-message {
  height: 20px;
}
.el-input--mini .el-input__inner {
  height: 0.28rem;
  line-height: 0.28rem;
  background-color: #f2f3f5;
}
.el-input--mini .el-input__icon {
  line-height: 0.13rem;
}
.header_img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recruit_box {
  width: 80%;
  margin: 0.4rem 0;
  padding: 0.28rem;
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.recruit_tit {
  font-size: 14px;
  font-weight: 600;
}
.info_tit {
  margin-top: 12px;
  font-size: 0.15rem;
}
.info_tit > span {
  color: red;
}
.recruit_content {
  display: flex;
}
.recruit_left {
  flex: 1;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: column;
}
.recruit_left > input {
  height: 16px;
  background-color: #f6f6f6;
  font-size: 8px;
}
.recruit_right {
  margin-top: 0.2rem;
  flex: 1;
}
.btn {
  margin-left: 0.1rem;
  width: 80px;
  height: 22px;
  border-radius: 9px;
  line-height: 22px;
  text-align: center;
  background-color: #f78044;
  font-size: 0.12rem;
  color: white;
  letter-spacing: 0.01rem;
}
.commit {
  margin: 0 auto;
  width: 140px;
  margin-top: 0.2rem;
  letter-spacing: 0.02rem;
}
.el-input__inner, .el-textarea__inner{
    font-size: 0.15rem;
    padding-left: 0.1rem;
}
</style>