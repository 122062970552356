<template>
  <div>
    <div class="joinItem_box">
      <div class="item_top_tit">
        <div class="top_tit">入驻点约</div>
        <div class="top_subtitle">
          点约按摩,技师火爆招募中,手机一键接单,无需坐班
        </div>
      </div>
      <div class="item_bottom_inp">
        <div class="bottom_inp_box">
          <input type="text" v-model="phone" placeholder="填写手机号" />
        </div>
        <div class="commit_btn" @click="commit()">申请入驻</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqPartner } from "@/api/requests"
export default {
  name: "joinItem",
  data() {
    return {
      phone:""
    };
  },
  methods: {
    // 提交入驻
    async commit() {
      return 
      const res = await reqPartner({phone:this.phone,code:"+86"})
      console.log("22",res)
      // alert("请输入电话号码")
    },
  },
};
</script>

<style>
.joinItem_box {
  width: 100%;
  background-color: rgb(53, 54, 56);
  color: white;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item_top_tit {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top_tit {
  font-size: 20px;
  letter-spacing: 3px;
}
.top_subtitle {
  font-size: 10px;
  letter-spacing: 2px;
  margin-top: 4px;
}
.bottom_inp_box {
  width: 200px;
  height: 25px;
  border-radius: 6px 0 0 6px;
  background-color: white;
}
.bottom_inp_box > input {
  border: transparent;
  outline: none;
  border-radius: 6px 0 0 6px;
  height: 25px;
  font-size: 8px;
  padding-left: 6px;
}
.item_bottom_inp {
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.commit_btn {
  width: 80px;
  height: 25px;
  border-radius: 0 6px 6px 0;
  background: linear-gradient(to right, #ff7b21, #ffa05f);
  text-align: center;
  line-height: 25px;
  font-size: 10px;
}
</style>