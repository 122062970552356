<template>
  <div>
    <myHeader></myHeader>
    <keep-alive include="home">
      <router-view></router-view>
    </keep-alive>
    <myFooter></myFooter>
    <el-backtop :bottom="100" style="width: 0.5rem;height: 0.5rem;">
      <i class="el-icon-upload2" style="color: #f18101;"></i>
  </el-backtop>
  </div>
</template>

<script>
import myHeader from "@/component/myHeader.vue";
import myFooter from "@/component/myFooter.vue";
import lodash from "lodash";
export default {
  name: "App",
  components: { myHeader, myFooter },
  data() {
    return {};
  },
  mounted() {
    this.watchWidth();
  },
  methods: {
    //监视视口宽度
    watchWidth() {
      window.onresize = lodash.throttle(() => {
        this.$store.commit("setSize", document.body.clientWidth);
      }, 400);
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

</style>