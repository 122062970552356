<template>
  <div>
    <div class="page_main_box">
      <div class="page_content_box">
        <div class="abot_top">
          <div class="top_content">
            <div class="page_tit_box">
              <img src="../../public/images/aboutUs/abouticon.png" alt="" />
              <div class="page_tit_text">实力开发平台</div>
              <div class="page_subtitle">诚信经营 热情服务</div>
            </div>
            <div class="about_item_box">
              <div class="about_item_tit">关于点约到位公司</div>
              <div class="item_content_box">
                <div class="about_item_line"></div>
                <div class="about_item_content">
                  点约到位是一家专注于020上门服务的互联网企业，以舒心服务为本，前沿技术为核心，构建专业正规、健康养生、省心便捷的点约上门服务平台。致力整合全国专业按摩技师资源，经过真人实名上岗资质认证，为商旅及本地高端消费人士提供养生保健上门到家服务。传承手艺人古法技艺，守正促进中国专业保健推拿师+健康养生的线上线下深度融合发展。
                </div>
              </div>
            </div>
          </div>
          <div class="top_img">
            <img
              src="../../public/images/aboutUs/aboutimg.png"
              style="width: 75%"
            />
          </div>
        </div>
        <div style="display: flex;flex-direction: column; width: 100%;">
          <div class="about_item_box">
            <div class="about_item_tit">企业使命</div>
            <div class="item_content_box">
              <div class="about_item_line"></div>
              <div class="about_item_content">
                掌握和使用先进的前沿技术，高效整合一切有效资源，达成技师和用户养生滋润健康的愿望
              </div>
            </div>
          </div>
          <div class="about_item_box">
            <div class="about_item_tit">企业愿景</div>
            <div class="item_content_box">
              <div class="about_item_line"></div>
              <div class="about_item_content">
                让用户随时随地，极速获得专业按摩上门服务
              </div>
            </div>
            <div class="item_content_box">
              <div class="about_item_line"></div>
              <div class="about_item_content">
                让所有乐意为更多用户缓解身心疲累的技师，都能方便快捷地达成愿望
              </div>
            </div>
          </div>
          <div class="about_item_box">
            <div class="about_item_tit">核心价值观</div>
            <div class="item_content_box">
              <div class="about_item_line"></div>
              <div class="about_item_content">
                积攒和激发健康能量!
              </div>
            </div>
          </div>
          <div class="about_item_box">
            <div class="about_item_tit">联系我们</div>
            <div class="item_content_box">
              <div class="about_item_line"></div>
              <div class="about_item_content">
                公司总部地址:深圳市龙岗区富安大道8号海源创新中心8楼
              </div>
            </div>
            <div class="item_content_box">
              <div class="about_item_line"></div>
              <div class="about_item_content">
                客服热线:+86 188-2018-0587
              </div>
            </div>
            <div class="item_content_box">
              <div class="about_item_line"></div>
              <div class="about_item_content">
                邮箱:3452446067@qq.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  data() {
    return {};
  },
};
</script>

<style scoped>
.page_main_box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page_content_box {
  width: 86%;
  padding-bottom:80px ;
}
.abot_top {
  display: flex;
  justify-content: space-between;
}
.top_content {
  flex: 1;
}
.top_img {
  flex: 1;
}
.page_tit_text {
  padding-top: 6px;
  padding-left: 15px;
  font-weight: 600;
  font-size: 12px;
  color: #353638;
  letter-spacing: 1px;
}
.page_subtitle {
  font-size: 8px;
  color: #353638;
  padding-left: 15px;
}
.page_tit_box {
  position: relative;
}
.page_tit_box > img {
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
}
.about_item_tit {
  font-size: 9px;
  color: #353638;
  font-weight: 600;
  padding-left: 15px;
  margin: 10px 0;
}
.item_content_box {
  width: 100%;
  position: relative;
}
.about_item_line {
  width: 2px;
  background-color: #f18101;
  height: 9px;
  position: absolute;
  top: 4px;
  left: 10px;
}
.about_item_content {
  font-size: 0.110rem;
  line-height: 0.346rem;
  padding-right: 22px;
  text-indent: 2em;
  position: relative;
}
</style>