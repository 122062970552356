var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-image":"linear-gradient(to right,rgb(251, 122, 71),rgb(238, 126, 106))"}},[_c('div',{staticClass:"header_img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../public/images/recruitArti/banner.png")}}),_c('div',{staticClass:"recruit_box",staticStyle:{"margin-top":"-0.2rem"}},[_c('span',{staticClass:"recruit_tit"},[_vm._v("请填写您的基本信息")]),_c('div',{staticClass:"recruit_content"},[_c('div',{staticClass:"recruit_left"},[_c('div',{staticClass:"info_tit"},[_vm._v(" 您的姓名"),_c('span',[_vm._v(" *")]),(false)?_c('span',{staticStyle:{"font-size":"0.098rem"}},[_vm._v("未填写")]):_vm._e()]),_c('el-input',{staticStyle:{"width":"3.2rem"},attrs:{"placeholder":"请输入您的姓名","size":"mini","clearable":""},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_vm._m(0),_c('el-input',{staticStyle:{"width":"3.2rem"},attrs:{"placeholder":"请输入您的工作城市","size":"mini","clearable":""},model:{value:(_vm.data.city),callback:function ($$v) {_vm.$set(_vm.data, "city", $$v)},expression:"data.city"}}),_vm._m(1),_c('el-select',{staticStyle:{"width":"3.2rem"},attrs:{"placeholder":"请选择性别","size":"mini"},model:{value:(_vm.data.sex),callback:function ($$v) {_vm.$set(_vm.data, "sex", $$v)},expression:"data.sex"}},_vm._l(([
                { value: 1, label: '男' },
                { value: 2, label: '女' },
              ]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.label}})}),1),_vm._m(2),_c('el-input',{staticStyle:{"width":"3.2rem"},attrs:{"placeholder":"请输入您的年龄","size":"mini","clearable":""},model:{value:(_vm.data.age),callback:function ($$v) {_vm.$set(_vm.data, "age", $$v)},expression:"data.age"}}),_vm._m(3),_c('el-input',{staticStyle:{"width":"3.2rem"},attrs:{"placeholder":"请输入您的接单区域","size":"mini","clearable":""},model:{value:(_vm.data.region),callback:function ($$v) {_vm.$set(_vm.data, "region", $$v)},expression:"data.region"}})],1),_c('div',{staticClass:"recruit_right"},[_vm._m(4),_c('el-select',{staticStyle:{"width":"3.2rem"},attrs:{"placeholder":"请选择工作经验","size":"mini"},model:{value:(_vm.data.working_years),callback:function ($$v) {_vm.$set(_vm.data, "working_years", $$v)},expression:"data.working_years"}},_vm._l(([
                { value: 3, label: '一年以下' },
                { value: 4, label: '1~3年' },
                { value: 5, label: '3~7年' },
                { value: 6, label: '7年以上' },
              ]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.label}})}),1),_c('div',{staticClass:"info_tit"},[_vm._v("工作经历")]),_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入工作经历","maxlength":"100","show-word-limit":""},model:{value:(_vm.data.working_experience),callback:function ($$v) {_vm.$set(_vm.data, "working_experience", $$v)},expression:"data.working_experience"}}),_vm._m(5),_c('el-input',{staticStyle:{"width":"3.2rem"},attrs:{"placeholder":"请输入您的电话号码","size":"mini","clearable":""},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}}),_c('div',{staticStyle:{"display":"flex","margin-top":"0.12rem","align-items":"center"}},[_c('el-input',{staticStyle:{"width":"1.6rem"},attrs:{"placeholder":"请输入验证码","size":"mini","clearable":""},model:{value:(_vm.data.verify_code),callback:function ($$v) {_vm.$set(_vm.data, "verify_code", $$v)},expression:"data.verify_code"}}),_c('div',{staticClass:"btn",style:({width:_vm.isMobile?'2.5rem':''}),on:{"click":_vm.getCode}},[_vm._v(_vm._s(_vm.text))])],1),_c('div',{staticClass:"btn commit",staticStyle:{"margin-left":"1.3rem"},on:{"click":_vm.commit}},[_vm._v(" 提交信息 ")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_tit"},[_vm._v(" 您的工作城市 (例如 : 深圳 )"),_c('span',[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_tit"},[_vm._v("性别"),_c('span',[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_tit"},[_vm._v("年龄"),_c('span',[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_tit"},[_vm._v("接单区域 ( 例如:深圳 )"),_c('span',[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_tit"},[_vm._v("工作经验"),_c('span',[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_tit"},[_vm._v("请输入电话号码"),_c('span',[_vm._v(" *")])])
}]

export { render, staticRenderFns }