<template>
  <div class="footer_main_box">
    <div class="code_img_box">
      <img src="../../public/images/home/weixin.png" alt="">
    </div>
    <div class="company_info">
      <div>点约到位（深圳）科技有限公司</div>
      <div><a href="https://beian.miit.gov.cn" style="color: gray;">粤ICP备2023015524号</a></div>
      <div><a href="https://www.goodyue520.com/document/userPriv.html" style="color: gray;">隐私政策</a></div>
      <div>客服热线:+86 188-2018-0587</div>
      <div class="img_box"><img src="../../public/images/wx.png"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702005620" style="color: gray;margin-left: 0.02rem;">粤公网安备 44030702005620号</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name:"myFooter",
  data(){
    return{

    }
  },
  
}
</script>

<style>
.footer_main_box{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 14px 0;
  align-content: center;
  background-color: #F1F1F1;
}
.code_img_box{
  margin: auto 0;
}
.code_img_box>img{
  width: 50px;
  height: 50px;
}
.company_info{
  color: gray;
  font-size: 6px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.company_info>div{
  margin-top: 4px;
  display: flex;
  align-items: center;
}
.img_box>img{
  width: 0.16rem;
  border-radius: 0.5rem;
}
</style>